// import React from "react";
import React, { useEffect } from "react";
import owner1 from "./images/about/owner1.JPG";
import owner2 from "./images/about/owner2.JPG";
import office1 from "./images/about/002.JPG";
import office2 from "./images/about/003.jpg";
import { Carousel } from "react-bootstrap";
import image from "./images/about/1.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  // style="background: rgba(0, 0, 0, 0) url(images/bg/5.jpg) no-repeat scroll center center / cover ;"
  return (
    <div
          
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
    <div className="container" id="about-owner">
      {/* <div className="ht__bradcaump__area" data--black__overlay="6" >
            <div className="ht__bradcaump__wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="bradcaump__inner text-center">
                                <h2 className="bradcaump-title">About Us</h2>
                                <nav className="bradcaump-inner">
                                  <a className="breadcrumb-item">Home</a>
                                  <span className="brd-separetor">-</span>
                                  <span className="breadcrumb-item active">About Us</span>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      <div className="text-center">
        <h1 className="font-face-au">ABOUT US</h1>
      </div>  

      <div className="row">
        <div className="col-md-6 pt-4 mt-4 pb-4 mb-4 text-justify">
          <h3
            className="align-items-center"
            style={{ fontSize: "18px", fontWeight: "400", lineHeight: "1.5", fontFamily: "sans-serif" }}
          >
            Founded in 2001, KP Construction was started by Mr.K.Prabhu, an
            aspiring diplomat.
            <br />
            With an uncompromising passion for the highest quality and
            detailing, exceptional design and execution, In 2013 Our Company was
            developed and renowned in to KP Civil Construction PVT LTD.
            <br />
            We also hold a certificate for <b>ISO 14001:2015</b> for environmental
            management system and <b>ISO 9001:2015</b> for Quality management system.
            <br />
            Our <b>Major projects</b> include KP Green Meadows, KP Kavin, KP
            Srinivasa, KP Skyway, KP Akshaya, KP Jayam, KP Saibala, KP
            Santhosema, KP Neyveli Nivas, KP Neyveli Homes, KP Maple, KP
            Aishwariyam, KP Nakshatra and lot more to account on.
            <br />
            Our Company symbolises best in
            <b>
              {" "}
              Customer satisfaction, Integrity, Execution & Excellency in
              Commitment.
            </b>
            <br />
            Spanning over the decades, together with our team we have delivered
            landmark projects{" "}
            <b>
              like luxury apartments, villas, duplex homes covering more than
              3 lakh sqft built up area
            </b>{" "}
            at prime location of South Chennai, Sithalapakkam.
            <br />
            
            We have worked successfully under the toughest situation that paved
            the way for our <b>immutable development.</b>
            <br />
            We expertise in satisfying the <b>
              finest needs of our customers
            </b>{" "}
            with a forecast view of future <b>sustainability.</b>
            <br />
            Our Zeal is to provide a{" "}
            <b>
              sustainable quality and safety environment all over the globe.
            </b>
            <br />
            Our Projects have demonstrated excellent{" "}
            <b>growth in cultural and ethical values of urban lifestyle</b>
            <br />
            <b>
              Together we amplify your Happiness through our Service.
              <br />
              <br />
              <b>
              Vision:
              <br />
              </b>
            </b>
            To create a world-className sustainable living environment over the
            globe.
            <br />
            <br />
            <b>
              Our Mission:
              <br />
            </b>
            To build and develop our community with leading standards and
            commitment to excellence with business integrity without
            compromising on the quality of the environment.
            <br />
         
            <br/>
            <br/>
          </h3>
        </div>
        <div className="col-md-6 pt-4 mt-4 pb-4 mb-40 ">
        <Carousel>
                  <Carousel.Item>
                    <div className="testimonial">
                      <div className="testimonial__thumb">
                        <img src={owner1} alt="clint image" />
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="testimonial">
                      <div className="testimonial__thumb">
                        <img src={owner2} alt="clint image" />
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="testimonial">
                      <div className="testimonial__thumb">
                        <img src={office1} alt="clint image" />
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="testimonial">
                      <div className="testimonial__thumb">
                        <img src={office2} alt="clint image" />
                      </div>
                      {/* <div className="testimonial__details">
                        <div className="tes__icon">
                          <i className="fa fa-quote-right"></i>
                        </div>
                        <p>
                          On the other hand, we denounce with righteous
                          indignation and the tdislike men who are beguiled{" "}
                        </p>
                        <h2>
                          <a href="#">Ken Williams</a>
                        </h2>
                        <h4>CEO, Alves</h4>
                      </div> */}
                    </div>
                  </Carousel.Item>
                </Carousel>
          {/* <img
            src="https://kpcivilconstruction.com/wp-content/uploads/2021/04/KP.jpg"
            alt="owner"
          /> */}
        </div>
      </div>
    </div>
    </div>
  );
};

export default About;

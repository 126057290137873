import React, { useState, useEffect, useRef } from "react";
import emailjs from "emailjs-com";
import { send } from "emailjs-com";

import { notif, sendEnquiry } from "../../appFunctions";
import { hasEmptyFields } from "../../jsFunctions";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Enquire = ({ afterSend }) => {
  const formRef = useRef();
  const [isSending, setIsSending] = useState(false);
  const sendButtonTxt = isSending ? "Sending..." : "Send Message";

  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    telephone: "",
    subject: "",
    message: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    {
      /* --- METHOD TO SEND THE MAIL --- */
    }
    console.log({ toSend });

    if (hasEmptyFields(toSend)) {
      notif.error({
        msg: `Cannot Send an empty response
Please fill all the fields.`,
        timeout: 15000,
      });
      setIsSending(false);
      return;
    }

    setIsSending(true);

    await sendEnquiry({
      sendData: toSend,
      afterSend: () => {
        setIsSending(false);
        afterSend();
      },
    });
    
    try {
      // e.target.reset();
      formRef.current.reset();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value }, e.target.reset);
  };

  return (
    <div className="col-md-12 col-lg-12  col-sm-12 col-xs-12 smt-40">
      <div className="enquire_projects">
        <div className="section__title text-center">
          <h2 className="title__line_enquiry">
            ENQUIRY<span className="text--theme-enquiry"> </span>
          </h2>
        </div>
        <form
          ref={formRef}
          id="contact-form"
          action="https://d29u17ylf1ylz9.cloudfront.net/simply-construction/mail-faq.php"
          method="post"
          onSubmit={onSubmit}
        >
          <div className="clint__comment__form">
            <div className="single__cl__form">
              <input
                name="name"
                type="text"
                placeholder="Name"
                value={toSend.name}
                onChange={handleChange}
              />
              <input
                name="email"
                type="email"
                placeholder="Email"
                value={toSend.email}
                onChange={handleChange}
              />
            </div>
            <div className="single__cl__form">
              <input
                name="telephone"
                type="tel"
                placeholder="Phone"
                value={toSend.telephone}
                onChange={handleChange}
              />
              <input
                name="subject"
                type="text"
                placeholder="Subject"
                value={toSend.subject}
                onChange={handleChange}
              />
            </div>
            <div className="single__cl__message">
              <textarea
                name="message"
                placeholder="Message"
                value={toSend.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="clint__submit__btn">
              <button className="submit htc__btn" type="submit">
                {sendButtonTxt}
              </button>
            </div>
          </div>
        </form>
        <p className="form-messege"></p>
        {/* <ToastContainer /> */}
      </div>
    </div>
  );
};

export default Enquire;

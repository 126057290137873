import React from "react";
import { Carousel } from "react-bootstrap";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";
import img1 from "../Projects/images/completed/15-1.jpeg";
import img2 from "../images/landing page/002.jpg";
import img3 from "../Projects/images/completed/14-1.jpeg";
import img4 from "../images/landing page/004.jpg";
import img5 from "../images/landing page/005.jpg";
import img6 from "../images/landing page/006.jpg";
import img7 from "../images/landing page/007.jpg";

// import ReactPlayer from "react-player";
// import video from "../Projects/video/Kpconstructions.mp4";


const Slider = () => {
  const carouselImages = [
    {
      id: 1,
      src: img1,
      // alt: "First Slide",
    },
      {
      id: 2,
      src: img2,
      alt: "Second Slide",
    },
    {
      id: 3,
      src: img3,
      alt: "Third Slide",
    },
    {
      id: 4,
      src: img4,
      alt: "Fourth Slide",
    },
    {
      id: 5,
      src: img5,
      alt: "Fifth Slide",
    },
    {
      id: 6,
      src: img6,
      alt: "Sixth Slide",
    },
    {
      id: 7,
      src: img7,
      alt: "Seventh Slide",
    },
    


  ];

  return (
    <SimpleReactLightbox>
      <div>
        <SRLWrapper>
          <Carousel>
            {carouselImages.map((image) => (
              <Carousel.Item className="slideImage" key={image.id}>
                <img
                  className="d-block w-100"
                  src={image.src}
                  alt={image.alt}
                  />
                  <div className="carousel-control-prev" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  </div>
                  {/* <ReactPlayer className="d-block w-100 h-50"
                  url={image.src}
                  src={image.src}
                  width="100%"
                  pip={true}
                  controls={true}
                  playing={false}
                  />
                */}
                {/* <Video>
                <source src={Kpconstructions} />
              </Video>  */}
            </Carousel.Item>
              
            ))}
          </Carousel>
        </SRLWrapper>
      </div>
    </SimpleReactLightbox>
  );
};

export default Slider;

import React, { useEffect, useState } from "react";
//import "../Projects/CustomerReview.css";
import "../Projects/Review.css";
import { Carousel } from "react-bootstrap";
// import Aos from "aos";
// import "aos/dist/aos.css";

const Reviews = () => {
   
    // useEffect(() => {
    //     Aos.init();
    //   }, []);
    
  return (
    <div>
       <section
        id="reviews"
        className="htc__team__area bg__white h1__team--one ptb--30 text__pos"
      >
          <br/>
          <br/>
          <br/>
    <div className="container main">
    <center>
      <div className="CustTitle text-center">
        <h2 className="title_testimonial">Testimonial</h2>
      </div>
      {/* <br/>
      <br/> */}
      <div className="container mt-5">
    <div className="row">
        
        <div className="col-md-4 rounded-center">
            <div className="testimonial-2 rounded"><img className="mb-3" src="https://i.imgur.com/OEJ9eQF.png" width="40"/>
                <p>Highly recommended and giving 5+ star. Very polite and respectable staff with more knowledge in construction. Mr.K.Prabhu sir is really talented and excellent knowledge in all perspective.<br/><br/></p>
                <div className="d-flex flex-row align-items-center">
                    <div className="ml-2 review-message"><span className="d-block name">RajaShanmugam Murugesan</span></div>
                </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="testimonial-1 rounded"><img className="mb-3" src="https://i.imgur.com/ECXzJ1k.png" width="40"/>
                <p>Good company for budget home buyers. KP Construction MD K.Prabhu sir so genuine and reliable.
Great work and very excellent skilled (Maheshwari) mam.
Wishes to the team for many many success.<br/><br/></p>
                <div className="d-flex flex-row align-items-center">
                    <div className="ml-2 review-message"><span className="d-block name">Pavithra Kannan</span></div>
                </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="testimonial-2 rounded"><img className="mb-3" src="https://i.imgur.com/OEJ9eQF.png" width="40"/>
                <p>KP Civil Construction is a professional detail oriented company that would go beyond expectations in customer satisfaction. Communication throughout the project was outstanding.  Their rates are competitive and fair.
<br/><br/></p>
                <div className="d-flex flex-row align-items-center">
                    <div className="ml-2 review-message"><span className="d-block name">Ramya Sivakumar</span></div>
                </div>
            </div>
        </div>
        {/* <div className="col-md-4">
            <div className="testimonial-1 rounded"><img className="mb-3" src="https://i.imgur.com/ECXzJ1k.png" width="40"/>
                <p>One of the best civil construction company. All paper work was clear and perfect. <br/>Very cooperative and flexible for the changes we make with the construction and interiors.<br/><br/></p>
                <div className="d-flex flex-row align-items-center">
                    <div className="ml-2 review-message"><span className="d-block name">Presica Jeni</span></div>
                </div>
            </div>
        </div> */}
        <div className="col-md-4">
            <div className="testimonial-1 rounded"><img className="mb-3" src="https://i.imgur.com/ECXzJ1k.png" width="40"/>
            <p>Most trusted builder. Maintaining good quality in construction. Eco-friendly approach and helping at anytime making us feel better and happy.
Especially workers be pro-active in work hatts-off team.<br/><br/></p>
                <div className="d-flex flex-row align-items-center">
                    <div className="ml-2 review-message"><span className="d-block name">Ramesh Raju</span></div>
                </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="testimonial-2 rounded"><img className="mb-3" src="https://i.imgur.com/OEJ9eQF.png" width="40"/>
                <p>Best and genuine civil constructor & promoters. <br/>
                Properties offered with good and best in className amenities.<br/><br/><br/></p>
                <div className="d-flex flex-row align-items-center">
                    <div className="ml-2 review-message"><span className="d-block nameLivin">Xavier Livin</span></div>
                </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="testimonial-1 rounded"><img className="mb-3" src="https://i.imgur.com/ECXzJ1k.png" width="40"/>
                <p>Excellent builder. The Managing Director Mr. K.Prabhu is the honest man.He had a clear vision. There is no deviation from his speech and his work. Best place to buy flat and plots at Sithalapakkam<br/><br/></p>
                <div className="d-flex flex-row align-items-center">
                    <div className="ml-2 review-message"><span className="d-block name">Selvamaheshwari Jeyapaul</span></div>
                </div>
            </div>
        </div>
        
    </div>
</div>

</center>
        </div>
       <br/>

        </section>
        <br/>
        <br/>
        </div>
        
    );
};

export default Reviews;





      {/* <div className="container mt-5">
    <div className="row d-flex justify-content-center align-item-center testimonials">
        <div className="col-md-4">
            <div className="card ">
                <div className="content py-4 px-3 text-center">
                    <p>"Good company for budget home buyers. KP Construction MD K.Prabhu sir so genuine and reliable.
Great work and very excellent skilled (Maheshwari) mam. Once again am happy to say thank you 😊
Wishes to the team for many many success 😊😊😊"<br/><br/><br/></p>
                </div>
                <div className="review text-center p-4 text-white"> 
                    <h3 className="mb-0 mt-3">Pavithra Kannan</h3>
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <div className="card ">
                <div className="content py-4 px-3 text-center">
                    <p>"Highly recommended and giving 5+ star. My house construction started with this company without any problems. They helped me for all kind of document work in a very shorter timeframe. Very polite and respectable staff with more knowledge in construction and land properties documentation. Mr.K.Prabhu sir is really talented and excellent knowledge in all perspective."</p>
                </div>
                <div className="review text-center p-4 text-white">
                    <h3 className="mb-0 mt-3">RajaShanmugam Murugesan</h3>
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <div className="card ">
                <div className="content py-4 px-3 text-center">
                    <p>"One of the best civil construction company.
All paper work was clear and perfect.
Very cooperative and flexible for the changes we make with the construction and interiors.

Overall very happy with their work."<br/><br/><br/><br/> </p>
                </div>
                <div className="review text-center p-4 text-white"> 
                    <h3 className="mb-0 mt-3">Presica Jeni</h3>
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <div className="card ">
                <div className="content py-4 px-3 text-center">
                    <p>"Five star service from KP constructions. <br/>They are through professional and have solution to all the needs. It's a delight."</p>
                </div>
                <div className="review text-center p-4 text-white">
                    <h3 className="mb-0 mt-3">Sujatha Balu</h3>
                </div>
            </div>
        </div>

        <div className="col-md-4">
            <div className="card ">
                <div className="content py-4 px-3 text-center">
                    <p>"Best and genuine civil constructor & promoters. Properties offered with good and best in className amenities."<br/><br/></p>
                </div>
                <div className="review text-center p-4 text-white">
                    <h3 className="mb-0 mt-3">Xavier Livin</h3>
                </div>
            </div>
        </div>


        <div className="col-md-4">
            <div className="card ">
                <div className="content py-4 px-3 text-center">
                    <p>"Best Place to buy a property at Sithalapakkam. <br/>Good Team with work for purchase of asset.
We bought a Land from them."</p>
                </div>
                <div className="review text-center p-4 text-white">
                    <h3 className="mb-0 mt-3">Arul Prabu</h3>
                </div>
            </div>
        </div>

        
    </div>
</div>
 */}















      {/* <div className="container">
    <div id="demo" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <div className="carousel-caption">
                    <p>If Shai Reznik's TDD videos don't convince you to add automated testing your code, I don't know what will.This was the very best explanation of frameworks for brginners that I've ever seen. </p> 
                    <div id="image-caption">Nick Doe</div>
                </div>
            </div>
            <div className="carousel-item">
                <div className="carousel-caption">
                    <p>If Shai Reznik's TDD videos don't convince you to add automated testing your code, I don't know what will.This was the very best explanation of frameworks for brginners that I've ever seen.</p> <img src="https://i.imgur.com/QptVdsp.jpg" className="img-fluid"/>
                    <div id="image-caption">Cromption Greves</div>
                </div>
            </div>
            <div className="carousel-item">
                <div className="carousel-caption">
                    <p>If Shai Reznik's TDD videos don't convince you to add automated testing your code, I don't know what will.This was the very best explanation of frameworks for brginners that I've ever seen.</p> <img src="https://i.imgur.com/jQWThIn.jpg" className="img-fluid"/>
                    <div id="image-caption">Harry Mon</div>
                </div>
            </div>
        </div> <a className="carousel-control-prev" href="#demo" data-slide="prev"> <i className='fas fa-arrow-left'></i> </a> <a className="carousel-control-next" href="#demo" data-slide="next"> <i className='fas fa-arrow-right'></i> </a>
    </div>
</div> */}


{/* 
        <div className="row one">
            <div className="col-sm-4 pic">  </div>
            <div className="col-sm-8 test1"> "Good company for budget home buyers. KP Construction MD K.Prabhu sir so genuine and reliable.
Great work and very excellent skilled (Maheshwari) mam. Once again am happy to say thank you 😊
Wishes to the team for many many success 😊😊😊" <center> <br/>
                    <p className="by">~ <b>Pavithra Kannan</b></p>
                </center>
            </div>
        </div><br/><br/>
        <div className="row two">
            <div className="col-sm-8 test1"> "One of the best civil construction company.
All paper work was clear and perfect.
Very cooperative and flexible for the changes we make with the construction and interiors.

Overall very happy with their work." <center> <br/>
                    <p className="by">~ <b>Presica Jeni</b></p>
                </center>
            </div>
            <div className="col-sm-4 pic">  </div>
        </div><br/><br/>
        <div className="row one">
            <div className="col-sm-4 pic"> <img src="https://i.imgur.com/J6l19aF.jpg"/> </div>
            <div className="col-sm-8 test1"> "Highly recommended and giving 5+ star. This is in Sithalapaakam, Chennai area. My house construction started with this company without any problems. They helped me for all kind of document work in a very shorter timeframe. Very polite and respectable staff with more knowledge in construction and land properties documentation. Mr.K.Prabhu sir is really talented and excellent knowledge in all perspective." <center> <br/>
                    <p className="by">~ <b>RajaShanmugam Murugesan</b></p>
                </center> */}
            {/* </div>
        </div> */}
        
import React, { useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import Index from "./Components/Index/Index";
import "./assets/css/bootstrap.min.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/core.css";
import "./assets/css/shortcode/shortcodes.css";
import "./assets/style.css";
import "./assets/css/responsive.css";
import "./assets/css/custom.css";

import { Contact } from "./Components/Index/Contact";
// import Project from "./Components/Index/Project";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Components/Index/Footer";
import SimpleReactLightbox from "simple-react-lightbox";
import Project from "./Components/Projects/Project";
import { Testimonial as ContactPage } from "./Components/Index/Testimonial";
import About from "./Components/About";
import Bnav from "./Components/Bnav";
import ScrollToTop from "./Scroll.js";
import Reviews from "../src/Components/Index/Reviews";
import Popup from "./Components/Popup/popup";
import PopOnButton from "./Components/Popup/popOnButton";
// import $ from "jquery"

function App() {
  return (
    <>
      <Router>
        <Popup />
        <PopOnButton />
        <ScrollToTop />
        <Bnav />

        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/project" element={<Project />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/review" element={<Reviews />} /> */}
        </Routes>
        <Contact />
        <Footer />

        {/* WhatsApp icon */}
        <a
          href="https://wa.me/9381021203"
          className="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-whatsapp whatsapp-icon"></i>
        </a>
        <a
          href="tel:+91 93810 21203"
          className="call_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-phone phone-icon" aria-hidden="true"></i>
        </a>
        {/* <a
        href=""
        className="enquiry_float"
        target="_blank"
        rel="noopener noreferrer"
      >
      <i className="fa fa-comment comment-icon" aria-hidden="true"></i>
      </a> */}
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;

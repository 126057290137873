import React, { useEffect, useState } from "react";
import "./Gellery.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Carousel } from "react-bootstrap";
import img1_1 from "./images/completed/1-1.jpg"
import img1_2 from "./images/completed/1-2.jpeg"
import img1_3 from "./images/completed/1-3.jpeg"
import img1_4 from "./images/completed/1-4.jpeg"
import img1_5 from "./images/completed/1-5.jpeg"
import img1_6 from "./images/completed/1-6.jpeg"
import img2_1 from "./images/completed/2-1.jpeg"
import img3_1 from "./images/completed/3-1.jpeg"
import img3_2 from "./images/completed/3-2.jpeg"
import img4_1 from "./images/completed/4-1.jpeg"
import img5_1 from "./images/completed/5-1.jpeg"
import img5_2 from "./images/completed/5-2.jpeg"
import img5_3 from "./images/completed/5-3.jpeg"
import img5_4 from "./images/completed/5-4.jpeg"
import img6_1 from "./images/completed/6-1.jpeg"
import img7_1 from "./images/completed/7-1.jpeg"
import img8_1 from "./images/completed/8-1.jpeg"
import img8_2 from "./images/completed/8-2.jpeg"
import img9_2 from "./images/completed/9-2.jpeg"
import img9_1 from "./images/completed/9-1.jpeg"
import img9_3 from "./images/completed/9-3.jpeg"
import img10_1 from "./images/completed/10-1.jpeg"
import img11_1 from "./images/completed/11-1.jpeg"
import img12_1 from "./images/completed/12-1.jpeg"
import img13_1 from "./images/completed/13-1.jpeg"
import img14_1 from "./images/completed/14-1.jpeg"
import img15_1 from "./images/completed/15-1.jpeg"
import img15_2 from "./images/completed/15-2.jpeg"
import img15_3 from "./images/completed/15-3.jpeg"
import img15_4 from "./images/completed/15-4.jpeg"
import img16_1 from "./images/completed/16-1.jpeg"
import img17_1 from "./images/completed/17-1.jpeg"
import img18_1 from "./images/completed/18-1.jpeg"
import img20_1 from "./images/completed/20-1.png"
import img19_1 from "./images/completed/19-1.jpeg"
import img21_1 from "./images/completed/21-1.jpeg"
import img22_1 from "./images/completed/22-1.jpeg"
import img23_1 from "./images/completed/23-1.jpeg"
import img24_1 from "./images/completed/24-1.jpeg"
import img25_1 from "./images/completed/25-1.jpeg"
import img26_1 from "./images/completed/26-1.jpeg"
import img27_1 from "./images/completed/27-1.jpeg"
import img_1_1 from "./images/ongoing/1-1.jpeg"
import img_1_2 from "./images/ongoing/1-2.jpeg"
import img_1_3 from "./images/ongoing/1-3.jpeg"
import img_1_4 from "./images/ongoing/1-4.jpeg"
import img_2_1 from "./images/ongoing/2-1.jpeg"
import img_3_1 from "./images/ongoing/3-1.jpeg"
import m1 from "./images/future/m1.jpg"
import m2 from "./images/future/m2.jpg"
import m3 from "./images/future/m3.jpg"
import mc1 from "./images/future/mc1.jpg"
import mc2 from "./images/future/mc2.jpg"
import mc3 from "./images/future/mc3.jpg"
import s1 from "./images/future/S1.jpg"
import s2 from "./images/future/S2.jpg"
import s3 from "./images/future/S3.jpg"
import s4 from "./images/future/S4.jpg"

const images = [
  {
    id: "1",
    title: "Individual House",
    imageName: [img4_1],
    location: "Sankarapuram, Sithalapakkam",
    type: "Residential House",
    status: "Completed",
    area: "1200 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  
  {
    id: "2",
    title: "Individual House",
    imageName: [img3_1,img3_2],
    location: "Sankarapuram, Sithalapakkam",
    type: "Residential House",
    status: "Completed",
    area: "950 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "3",
    title: "INDIVIDUAL DUPLEX HOUSE",
    imageName: [img5_1,img5_2,img5_3,img5_4],
    location: "Sankarapuram, Sithalapakkam",
    type: "Residential Apartments",
    status: "Completed",
    area: "1800 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "4",
    title: "INDIVIDUAL FLATS",
    imageName: [img1_1,img1_2,img1_3,img1_4,img1_5,img1_6],
    location: "Valasaravakkam",
    units: "3 units with shop",
    type: "Residential Apartments",
    status: "Completed",
    area: "3500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "5",
    title: "KP LAKSHMI",
    imageName: [img2_1],
    location: "Sithalapakkam",
    units: " 6 units",
    type: "Residential Apartments",
    status: "Completed",
    area: "5000 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "6",
    title: "INDIVIDUAL HOUSE",
    imageName: [img6_1],
    location: "Sankarapuram, Sithalapakkam",
    type: "Residential Apartments",
    status: "Completed",
    area: "2000 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "7",
    title: "KP SRINIVASA",
    imageName: [img7_1],
    units: "6 Units",
    location: "Sankarapuram, Sithalapakkam",
    type: "Residential Apartments",
    status: "Completed",
    area: "4500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "8",
    title: "Individual Flats",
    imageName: [img8_1,img8_2],
    location: "Sithalapakkam",
    units: "3 units",
    type: "Residential Apartments",
    status: "Completed",
    area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "9",
    title: "KP VISHAKA",
    imageName: [img9_1,img9_2,img9_3],
    location: "Sankarapuram, Sithalapakkam ",
    units: "4 units",
    type: "Residential Apartments",
    status: "Completed",
    area: "3500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "10",
    title: "INDIVIDUAL DUPLEX HOUSE",
    imageName: [img10_1],
    location: "Sithalapakkam ",
    type: "Residential Apartments",
    status: "Completed",
    area: "2300 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "11",
    title: "KP ABINANDHAN",
    imageName: [img11_1],
    location: "Green park, Sithalapakkam",
    units: "4 units",
    type: "Residential Apartments",
    status: "Completed",
    area: "3500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "12",
    title: "KP KAVIN",
    imageName: [img12_1],
    location: "Green park, Sithalapakkam",
    units: "6 units",
    type: "Residential Apartments",
    status: "Completed",
    // area: "3500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "13",
    title: "Individual Bungalow House",
    imageName: [img13_1],
    location: "Sithalapakkam",
    // units: "6 units",
    type: "Residential Apartments",
    status: "Completed",
    area: "3000 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "14",
    title: "Individual House",
    imageName: [img14_1],
    location: "Sithalapakkam",
    // units: "6 units",
    type: "Residential Apartments",
    status: "Completed",
    area: "850 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "15",
    title: "INDIVIDUAL DUPLEX HOUSE",
    imageName: [img15_1,img15_2,img15_3,img15_4],
    location: "Vengaivasal, Sithalapakkam",
    // units: "6 units",
    type: "Residential Apartments",
    status: "Completed",
    area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "16",
    title: "INDIVIDUAL DUPLEX HOUSE",
    imageName: [img16_1],
    location: "Sangarapuram, Sithalapakkam",
    // units: "6 units",
    type: "Residential Apartments",
    status: "Completed",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "17",
    title: "KP SANTHOSHEMA",
    imageName: [img17_1],
    location: "Chrompet",
    units: "6 units",
    type: "Residential Apartments",
    status: " Completed,1unsold (1BHK)",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "18",
    title: "KP VILLAS",
    imageName: [img18_1],
    location: "Vinobha nagar, Sithalapakkam",
    units: "3 units",
    type: "Deluxe Independent house",
    status: " Completed,sold",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "19",
    title: "KP SKYWAY",
    imageName: [img19_1],
    location: "Sky lab, Sithalapakkam",
    units: "6 units",
    type: "Residential apartments",
    status: " Completed,sold",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "20",
    title: "KP SAIBALA",
    imageName: [img20_1],
    location: "Velachery",
    units: "6 units",
    type: "Residential apartments",
    status: " Completed,sold",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "21",
    title: "KP NEVYIL Homes",
    imageName: [img21_1],
    location: "Senthamil nagar, Sithalapakkam",
    units: "11 units",
    type: "Residential apartments",
    status: " Completed,sold",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "22",
    title: "KP NEVYIL NIVAS",
    imageName: [img22_1],
    location: "Sangarapuram, Sithalapakkam",
    units: "11 units",
    type: "Residential apartments",
    status: " Completed,sold",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "23",
    title: "KP JAYAM",
    imageName: [img23_1],
    location: "Sabari Salai",
    units: "4 units",
    type: "Residential apartments",
    status: " Completed,1 unsold",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "24",
    title: "KP AKSHAYA",
    imageName: [img24_1],
    location: "Aravindh Avenue, Sithalapakkam",
    units: "6 units",
    type: "Residential apartments",
    status: " Completed,sold",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "25",
    title: "INDIVIDUAL HOME",
    imageName: [img25_1],
    location: "Sangarapuram, Sithalapakkam",
    units: "single (2BHK)",
    type: "Residential",
    status: " Completed",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "26",
    title: "INDIVIDUAL BUNGALOW",
    imageName: [img26_1],
    location: "Vivekanandha nagar, Sithalapakkam",
    units: "single (4BHK)",
    type: "Residential",
    status: " Completed",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "27",
    title: "KP Aiswariyam",
    imageName: [img27_1],
    location: "Green park, Sithalapakkam",
    units: "4 units",
    type: "Residential",
    status: " Completed",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Completed",
  },
  {
    id: "28",
    title: "KP DHANVIN",
    imageName: [img_1_1,img_1_2,img_1_3,img_1_4],
    location: "Vetrivel Nagar, Sankarapuram",
    units: "6 units",
    type: "Residential Apartments",
    status: "2 Units available",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Ongoing",
  },
  {
    id: "29",
    title: "KP HARSHITH",
    imageName: [img_2_1],
    location: "Vivekananda Nagar, Sithalapakkam",
    units: "6 units",
    type: "Residential Apartments",
    status: "sold",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Ongoing",
  },
  {
    id: "30",
    title: "PREMIUM PLOTS @ TAMIL SELVI NAGAR",
    imageName: [img_3_1],
    location: "Tamil Selvi Nagar, Sangarapuram",
    // units: "6 units",
    // type: "Residential Apartments",
    // status: "2 Units available",
    // area: "2500 Sq.ft",
    // Sales: "",
    tag: "Ongoing",
  },
  {
    id: "31",
    title: "Kp Royal Enclave",
    imageName: [m1,m2,m3],
    location: "Melakottaiyur",
    units: "14 units",
    type: "Residential Plots - Gated",
    status: "Launched on 2022,Feb",
    Sales: "Ongoing",
    area: "0.5 Acre",
    tag: "Future",
  },
  {
    id: "32",
    title: "KP Green Meadows",
    imageName: [mc1,mc2,mc3],
    location: "Mahendra City",
    units: "81 units",
    type: "Residential Plots - Gated",
    status: "Launched on 2018", 
    Sales: "Ongoing",
    area: "3.25 Acre",
    tag: "Future",
  },
  {
    id: "33",
    title: "KP Springs",
    imageName: [s1,s2,s3,s4],
    location: "Sithalapakkam",
    units: "10 units",
    type: "Residential Plots - Gated",
    // status: "2 Units available",
    area: "33 cents",
    tag: "Future",
  },
];


function Gallery() {
  const [tag, setTag] = useState("Ongoing");
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    tag === "all"
      ? setFilteredImages(images.filter((image) => image.tag !== "Videos"))
      : setFilteredImages(images.filter((image) => image.tag === tag));
  }, [tag]);
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div
      data-aos="fade-zoom-in"
      // data-aos-offset="200"
      data-aos-easing="ease-out-cubic"
      // data-aos-duration="2000"
    >
    <SimpleReactLightbox>
      <div className="gallery">
        <div className="tags">
          <TagButton
            name="Future"
            tagActive={tag === "Future" ? true : false}
            handleSetTag={setTag}
          />{" "}
          <TagButton
            name="Ongoing"
            tagActive={tag === "Ongoing" ? true : false}
            handleSetTag={setTag}
          />{" "}
          <TagButton
            name="Completed"
            tagActive={tag === "Completed" ? true : false}
            handleSetTag={setTag}
          />{" "}
        </div>
        
        <div className="container-2">
          {filteredImages.map((image) => {
            return (
              // <Container>
              <div key={image.id} className="image-card-rounded-lg">
                <SRLWrapper>
                  <Carousel  nextLabel={"Next"}
        prevLabel={"Previous"}>
                    {image.imageName.map((image) => (
                      <Carousel.Item className="projectImages"> 
                      <div>
                        <img className="image-rounded-lg" src={image} alt=""/>
                        <a href={image}>
                        
                          {/* <LazyLoadImage
                            className="image-rounded-lg"
                            src={image}
                            alt=""
                            
                          /> */}
                        </a>
                        </div>
                      </Carousel.Item>
                    ))}
                    
                  </Carousel>
                  
                </SRLWrapper>
                
                <div className="px-3">
                  <h4 className="py-3 font-bold text-uppercase" style={{fontSize:"17px"}}>{image.title}</h4>
                  <div className="list-content">
                    <ul>
                      <li className="py-1">
                        <span className="font-bold">LOCATION :</span>{" "}
                        {image.location}
                      </li>
                      {image.units && <li className="py-1">
                        <span className="font-bold">UNITS :</span> {image.units}
                      </li>}
                      {image.type && <li className="py-1">
                        <span className="font-bold">TYPE :</span> {image.type}
                      </li>}
                     {image.area && <li className="py-1">
                        <span className="font-bold">AREA :</span> {image.area}
                      </li>}
                     {image.status && <li className="py-1">
                        <span className="font-bold">STATUS :</span> {image.status}
                      </li>}
                      {image.Sales && <li className="py-1">
                        <span className="font-bold">SALES :</span> {image.Sales}
                      </li>}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
          {filteredImages.length === 0 && <h1 style={{color:"orangered",textAlign:"center",marginTop:"20px",marginBottom:"200px",backgroundColor:"#eee"}}>No projects Found...!</h1>}
      </div>

    </SimpleReactLightbox>
    
    </div>
    

    
  );
}

const TagButton = ({ name, handleSetTag, tagActive }) => {
  return (
    <button
      className={`tag ${tagActive ? "active" : null}`}
      onClick={() => handleSetTag(name)}
    >
      {name.toUpperCase()}
    </button>
  );
};
export default Gallery;

import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import Enquire from './enquire';

const customStyles = {
  content : {
    backgroundColor       : '#0a0647',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginTop             : '6%',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement')

function PopOnButton(){
  var subtitle;

  const [modalIsOpen,setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#000';
  }

  function closeModal(){
    setIsOpen(false);
  }

    return (
      <div>
        <button className='enquiry_float' onClick={openModal}><i className="fa fa-comment comment-icon" aria-hidden="true"></i></button>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <Enquire afterSend = {closeModal} />
            
            <button  id="submitId" onClick={closeModal}> &times;</button>

          {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2> */}
          {/* <div>I am a modal</div>
          <form>
            <input />
            <button>tab navigation</button>
            <button>stays</button>
            <button>inside</button>
            <button>the modal</button>
          </form> */}
        </Modal>
      </div>
    );
}
export default PopOnButton;
// ReactDOM.render(<PopOnButton />);

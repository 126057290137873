import React, { useEffect } from "react";
import about4 from "../../assets/images/about/4.jpeg";
import CountUp from "react-countup";
// import bg10 from '../../assets/'
import Aos from "aos";
import "aos/dist/aos.css";
// import Counter from 'react-number-counter';

const Counter = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div
      data-aos="fade-zoom-in"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <section className="htc__counterup__area count--2 h1__countdown bg__gray">
        <div className="container-fluid">
          {/* <div><img src={about4} alt="images" /> */}
          <div className="row">
            {/* <div className="col-md-4 col-sm-12 col-xs-12">
              <div className="counterup__left__thumb">
                <img src={about4} alt="images" />
              </div>
            </div> */}
            <div className="col-md-12 backgroundImage">
              <div className="htc__callto__action">
                <h2>
                  Our Staggering Numbers
                  {/* <span className="text--theme">KP CIVIL</span> CONSTRUCTION{" "} */}
                </h2>
                <p>
                  For more than two decades we had a spotless record. The below
                  numbers speaks for itself.
                </p>
              </div>
              {/* <div className="htc__call__btn">
                  <a className="htc__btn" href="#">
                    BUY NOW
                  </a>
                </div> */}

              {/* <div className="htc__counterup__wrap">
                <div className="funfact">
                  <div className="fact__details">
                    <div className="funfact__count__inner">
                      <div className="fact__count "> */}
              <div className="hero-wrap alt">
                <div className="container mt">
                  <div className="row facts">
                    <div className="col-md-4 col-lg-3 col-sm-4 col-xs-4">
                      <div className="fl-wrap">
                        <div className="inline-facts-holder fl-wrap">
                          <div className="inline-facts">
                            <span className="count">
                              <CountUp
                                start={0}
                                end={598}
                                duration={3.5}
                                separator=" "
                                decimal=","
                                delay={0}
                              >
                                {/*
                                  {({ countUpRef, start }) => (
                                      <VisibilitySensor onChange={start}>
                                          <span ref={countUpRef} />
                                      </VisibilitySensor>
                                  )}
                                */}
                              </CountUp>
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className="fact__title">
                        <h4>PROJECTS</h4>
                      </div>
                    </div>

                    {/* </div>
                </div> */}

                    {/* <div className="funfact hidden-md">
                  <div className="fact__details">
                    <div className="funfact__count__inner">
                      <div className="fact__count "> */}
                    <div className="col-md-4 col-lg-3 col-sm-4 col-xs-4">
                      <div className="fl-wrap">
                        <div className="inline-facts-holder fl-wrap">
                          <div className="inline-facts facts-2">
                            <span className="count">
                              <CountUp
                                start={0}
                                end={128}
                                duration={3.5}
                                separator=" "
                                decimal=","
                              ></CountUp>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="fact__title">
                        <h4>CLIENTS</h4>
                      </div>
                    </div>

                    {/* <div className="funfact">
                  <div className="fact__details">
                    <div className="funfact__count__inner">
                      <div className="fact__count "> */}

                    <div className="col-md-4 col-lg-3 col-sm-4 col-xs-4">
                      <div className="fl-wrap">
                        <div className="inline-facts-holder fl-wrap">
                          <div className="inline-facts-3">
                            <span className="count">
                              <CountUp
                                start={0}
                                end={598}
                                duration={3.5}
                                separator=" "
                                decimal=","
                              ></CountUp>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="fact__title">
                        <h4>SUCCESS</h4>
                      </div>
                    </div>

                    {/* <div className="funfact">
                  <div className="fact__details">
                    <div className="funfact__count__inner">
                      <div className="fact__count ">
                        <span className="count">
                        <CountUp
  start={0}
  end={109}
  duration={3.5}
  separator=" "
  decimal=","
 ></CountUp>
                        </span>
                      </div>
                    </div>
                    <div className="fact__title">
                      <h2>AWARDS</h2>
                    </div>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
      </section>
      <br />
      <br />
    </div>
  );
};

export default Counter;

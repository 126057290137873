import React, { useState, useEffect } from "react";
import "../Popup/popup.css";
import KP from "./KP Dhanvin.jpg";

const Popup = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  const closePopup = () => {
    setShow(false);
  };
  return (
    <div
      id="popupMain"
      style={{ display: `${show === true ? "block" : "none"}` }}
      onClick={closePopup}
    >
      <div
        id="popup"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
         <img
           src={KP}
           alt="popup"
         /> 

        <button id="submitId" onClick={closePopup}>
          &times;
        </button>
       </div>
     </div>
  );
};

export default Popup;

















// import React, { Component } from "react";
// import "../assets/css/shortcode/popup.css";

// export default className PopUp extends Component {
//   handleClick = () => {
//    this.props.toggle();
//   };
// render() {
//   return (
//    <div className="modal">
//      <div className="modal_content">
//      <span className="close" onClick={this.handleClick}>&times;    </span>
//      <p>I'm A Pop Up!!!</p>
//     </div>
//    </div>
//   );
//  }
// }






// import React from 'react';
// import ReactDOM from 'react-dom';
// import Modal from 'react-modal';

// const customStyles = {
//   content : {
//     top                   : '50%',
//     left                  : '50%',
//     right                 : 'auto',
//     bottom                : 'auto',
//     marginRight           : '-50%',
//     transform             : 'translate(-50%, -50%)'
//   }
// };

// // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement')

// function PopUp(){
//   var subtitle;
//   const [modalIsOpen,setIsOpen] = React.useState(false);
//   function openModal() {
//     setIsOpen(true);
//   }

//   function afterOpenModal() {
//     // references are now sync'd and can be accessed.
//     subtitle.style.color = '#f00';
//   }

//   function closeModal(){
//     setIsOpen(false);
//   }

//     return (
//       <div>
//         <button onClick={openModal}>Open Modal</button>
//         <Modal
//           isOpen={modalIsOpen}
//           onAfterOpen={afterOpenModal}
//           onRequestClose={closeModal}
//           style={customStyles}
//           contentLabel="Example Modal"
//         >

//           <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2>
//           <button onClick={closeModal}>close</button>
//           <div>I am a modal</div>
//           <form>
//             <input />
//             <button>tab navigation</button>
//             <button>stays</button>
//             <button>inside</button>
//             <button>the modal</button>
//           </form>
//         </Modal>
//       </div>
//     );
// }
// export default PopUp;
// ReactDOM.render(<PopUp />);







// import React, { useEffect, useState } from "react";
// import "../assets/css/shortcode/popup.css";
// import $ from "jquery"

// const PopUp = () => {
//     React.useEffect(() => {
//         window.addEventListener("load", function(){
//             setTimeout(
//                 function open(event){
//                     document.querySelector(".popup").style.display = "block";
//                 },
//                 2000 
//             )
//         });
//         document.querySelector("#close").addEventListener("click", function(){
//             document.querySelector(".popup").style.display = "none";
//         });
//     }, []);

//     return (
//         <div className="popup">
//         <button id="close">&times;</button>
//         <h2>Automatic Pop-Up</h2>
//         <p>
//             Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita distinctio fugiat alias iure qui, commodi minima magni ullam aliquam dignissimos?
//         </p>
//         <a className="pop" href="#">Let's Go</a>
//     </div>

// );
// };


// export default PopUp;



//         <div id="myModal" className="modal fade">
//     <div className="modal-dialog">
//         <div className="modal-content">
//             <div className="modal-header">
//                 <h5 className="modal-title">Subscribe our Newsletter</h5>
//                 <button type="button" className="close" data-dismiss="modal">&times;</button>
//             </div>
//             <div className="modal-body">
// 				<p>Subscribe to our mailing list to get the latest updates straight in your inbox.</p>
//                 <form>
//                     <div className="form-group">
//                         <input type="text" className="form-control" placeholder="Name"/>
//                     </div>
//                     <div className="form-group">
//                         <input type="email" className="form-control" placeholder="Email Address"/>
//                     </div>
//                     <button type="submit" className="btn btn-primary">Subscribe</button>
//                 </form>
//             </div>
//         </div>
//     </div>
// </div>





// import React, { useEffect, useState } from "react";

// const PopUp = () => {



// return (

// <div className="popup">
// <button id="close">&times;</button>
// <h2>Automatic Pop-Up</h2>
// <p>
//     Lorem, ipsum dolor sit amet consectetur adipisicing elit. Expedita distinctio fugiat alias iure qui, commodi minima magni ullam aliquam dignissimos?
// </p>
// <a href="#">Let's Go</a>
// </div>


//     );
// };
// window.addEventListener("load", function(){
//     setTimeout(
//     function open(event){
//         document.querySelector(".popup").style.display = "block";
//     },
//     2000 
//     )
//     });
    
    
//     document.querySelector("#close").addEventListener("click", function(){
//     document.querySelector(".popup").style.display = "none";
//     });
// export default PopUp;

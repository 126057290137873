import emailjs from 'emailjs-com';
import { notif } from "./";
import { ToastContainer } from "react-bootstrap";

const serviceId = "service_q187dil";
const templateId = "template_znkul48";
const initId = "tk9N8YLaSOPddOmNm";

// const serviceId = "service_uclcv7n";
// const templateId = "template_oftqbcj";
// const initId = "DVDn6E0RqzDypKK9o";

emailjs.init(initId);

const sendEnquiry = ({ sendData, afterSend }) => {
  return new Promise((resolve, reject) => {
    emailjs.send (serviceId, templateId, sendData, initId)
      .then((response) => {
        notif.success({
          msg: `Thank You!
Your Enquiry has been submitted.
We will get back to you as soon as possible.`,
          timeout: 10000,
        });
        console.log("SUCCESS!", {
          status: response.status,
          res: response.text,
        });
        resolve(response);
      })
      .catch((err) => {
        notif.error({
          msg: `Oops!
We were not able to send your enquiry.
Please check your internet or contact us directly.`,
          timeout: 15000,
        });
        console.log("FAILED...", { err });
        reject(err);
      })
      .finally(() => {
        afterSend();
      });
  });
};

export default sendEnquiry;

import React, { useEffect } from "react";
import icon1 from "../../assets/images/others/icon/1.png";
import icon2 from "../../assets/images/others/icon/2.png";
import icon3 from "../../assets/images/others/icon/3.png";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <section
        id="about"
        className="htc__about__area about--2 text__pos ptb--150 bg__white"
      >
        <div
          className="container"
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="100"
        >
          <div className="row">
            <div className="col-xs-12">
              <div className="section__title text-center">
                <h2 className="title__line"><b>
                  ABOUT US
                  </b>
                  {/* <span className="text--theme">US</span> */}
                </h2>
                <p>
                  KP Civil Construction Pvt Ltd is one of the leading
                  construction company in Chennai. Being one of the builders in
                  India with the international quality certification, ISO
                  9001:2015 OWNER Mr.K.Prabhu
                </p>
                <div className="about__btn">
                    <a href="/about">READ MORE</a>
                  </div>
              </div>
            </div>
          </div>
          <div className="row mt--70">
            <div className="col-md-4 col-lg-3 col-lg-offset-1 col-sm-6 col-xs-12">
              <div className="about foo">
                <div className="about__inner">
                  <h2>
                    <a href="/about">OUR MISSION</a>
                  </h2>
                  <p>
                  To build and develop our community with leading standards and commitment to excellence with business integrity without compromising on the quality of the environment.
                  </p>
                  {/* <div className="about__btn">
                    <a href="/about">READ MORE</a>
                  </div> */}
                  {/* <div className="about__icon">
                    <img src={icon1} alt="icon images" />
                  </div> */}
                </div>
                <div className="about__inner about__hober__info">
                  <h2>
                    <a href="/about">OUR MISSION</a>
                  </h2>
                  <p>
                  To build and develop our community with leading standards and commitment to excellence with business integrity without compromising on the quality of the environment.
                  </p>
                  {/* <div className="about__btn">
                    <a href="/about">READ MORE</a>
                  </div> */}
                  {/* <div className="about__icon">
                    <img src={icon1} alt="icon images" />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 col-sm-6 col-xs-12">
              <div className="about foo">
                <div className="about__inner">
                  <h2>
                    <a href="/about">WHAT WE DO</a>
                  </h2>
                  <p>
                  Our Company symbolises best in Customer satisfaction, Integrity, Execution and Excellency in Commitment.
                  </p>
                  {/* <div className="about__btn">
                    <a href="/whatwedo">READ MORE</a>
                  </div>*/}
                  {/* <div className="about__icon">
                    <img src={icon2} alt="icon images" />
                  </div> */}
                </div>
                <div className="about__inner about__hober__info active">
                  <h2>
                    <a href="/about">WHAT WE DO</a>
                  </h2>
                  <p>
                  Our Company symbolises best in Customer satisfaction, Integrity, Execution and Excellency in Commitment.
                  </p>
                  {/*<div className="about__btn">
                    <a href="/about">READ MORE</a>
                  </div>*/}
                  {/* <div className="about__icon">
                    <img src={icon2} alt="icon images" />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3 hidden-sm col-xs-12">
              <div className="about foo">
                <div className="about__inner">
                  <h2>
                    <a href="/about">OUR VISION</a>
                  </h2>
                  <p>
                  To create a world-className sustainable living environment Over the globe.
                  </p>
                  {/* <div className="about__btn">
                    <a href="/about">READ MORE</a>
                  </div> */}
                  {/* <div className="about__icon">
                    <img src={icon3} alt="icon images" />
                  </div> */}
                </div>
                <div className="about__inner about__hober__info">
                  <h2>
                    <a href="/about">OUR VISION</a>
                  </h2>
                  <p>
                  To create a world-className sustainable living environment Over the globe.
                  </p>
                  {/* <div className="about__btn">
                    <a href="/about">READ MORE</a>
                  </div> */}
                  {/* <div className="about__icon">
                    <img src={icon3} alt="icon images" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text__shape">
          <h2
            style={{
              // backgroundColor: "#2666ae",
              // fontSize: "30px",
              padding: "10px",
            }}
          >
            KP CIVIL
          </h2>
        </div>
      </section>
    </div>
  );
};

export default About;

import React from "react";

// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

import { SocialIcon } from "react-social-icons";

// import blogsm1 from "../../assets/images/blog/sm-img/1.jpg";
// import blogsm2 from "../../assets/images/blog/sm-img/2.jpg";
// import blogsm3 from "../../assets/images/blog/sm-img/3.jpg";
// import blogsm4 from "../../assets/images/blog/sm-img/4.jpg";
// import blogsm5 from "../../assets/images/blog/sm-img/5.jpg";
// import blogsm6 from "../../assets/images/blog/sm-img/6.jpg";

const Footer = () => {
  // style="background: rgba(0, 0, 0, 0) url(images/bg/2.jpg) no-repeat fixed center center / cover ;"

  const navigate = useNavigate();

  return (
    <div>
      <footer className="htc__footer__area">
        <div className="footer__top ptb--130" data--1f2d30__overlay="9.5">
          <div className="container">
            <div className="row">
              <div className="htc__footer__wrap clearfix">
                <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 xmt-40">
                  <div className="footer foo">
                    <div className="footer__widget">
                      <h2 className="ft__title">ABOUT</h2>
                    </div>
                    <div className="ft__details">
                      <p>
                        KP Civil Construction Pvt Ltd is one of the leading
                        construction company in Chennai. Being one of the
                        builders in India with the international quality
                        certification, ISO 9001:2015
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 xmt-40">
                  <div className="footer quick__link foo">
                    <div className="footer__widget">
                      <h2 className="ft__title">QUICK LINKS</h2>
                    </div>
                    <div className="footer__link">
                      <ul className="ft__menu">
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/project">Projects</Link>
                        </li>
                        {/* <li>
                          <a href="#">Features</a>
                        </li> */}
                        {/* <li>
                          <a href="blog.html">blog</a>
                        </li> */}
                      </ul>
                      <ul className="ft__menu">
                        {/* <li>
                          <a href="#">Quote</a>
                        </li> */}
                        <li>
                          <Link
                            to="/#reviews"
                            onClick={() => {
                              setTimeout(() => {
                                navigate("/#reviews");
                              }, 100);
                            }}
                          >
                            Testimonials
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                        {/* <li>
                          <a href="#">Features</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 smt-40 xmt-40">
                  <div className="footer foo">
                    <div className="footer__widget">
                      <h2 className="ft__title">SOCIAL MEDIA</h2>
                    </div>
                    <ul className="footer__instagram col-md-3 col-lg-3 col-sm-6 col-xs-12 smt-40 xmt-40s">
                      <li>
                        <a href="#">
                          <SocialIcon
                            url="https://www.facebook.com/kpcivilconstructions"
                            target={"_blank"}
                            bgColor="#fff"
                            fgColor="#000"
                            style={{ height: 35, width: 35 }}
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <SocialIcon
                            url="https://instagram.com/kp_civil_construction?igshid=YmMyMTA2M2Y="
                            target={"_blank"}
                            bgColor="#fff"
                            fgColor="#000"
                            style={{ height: 35, width: 35 }}
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <SocialIcon
                            url="https://twitter.com/KPrabhu_civil?t=CTlSqmPwIGBOeKH3f4727w&s=08"
                            target={"_blank"}
                            bgColor="#fff"
                            fgColor="#000"
                            style={{ height: 35, width: 35 }}
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <SocialIcon
                            url="https://youtube.com/channel/UCYC4bz1fdNKr-fofjtfGtDQ"
                            target={"_blank"}
                            bgColor="#fff"
                            fgColor="#000"
                            style={{ height: 35, width: 35 }}
                          />
                        </a>
                      </li>
                    </ul>
                    {/* <ul className="footer__instagram">
                      <li>
                        <a
                          href="https://www.facebook.com/pages/category/Construction-Company/K-P-Constructions-117165396620781/"
                          target="_blank"
                        >
                          <img src={Facebook} alt="FbIcon" className="fb" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://instagram.com/kp_civil_construction?utm_medium=copy_link"
                          target="_blank"
                        >
                          <img src={Instagram} alt="InstaIcon" className="insta" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/kpcivilconstruc?s=08"
                          target="_blank"
                        >
                          <img src={Twitter} alt="TwitterIcon" id="twitter-icon-footer" />
                        </a>
                      </li>
                      <li>
                        <a href="" target="_blank">
                          <img src={Youtube} alt="YoutubeIcon" id="youtube-icon-footer" />
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                {/* <div className="col-md-3 col-lg-2 col-lg-offset-1 col-sm-6 col-xs-12 smt-40 xmt-40">
                  <div className="footer foo">
                    <div className="footer__widget">
                      <h2 className="ft__title">INQUARY</h2>
                      <div className="footer__link">
                        <ul className="ft__menu">
                          <li>
                            <a href="#">Construction</a>
                          </li>
                          <li>
                            <a href="#">Architecture</a>
                          </li>
                          <li>
                            <a href="#">Plambing</a>
                          </li>
                          <li>
                            <a href="#">Plambing</a>
                          </li>
                        </ul>
                        <ul className="ft__menu">
                          <li>
                            <a href="#">Painting</a>
                          </li>
                          <li>
                            <a href="#">Roofing</a>
                          </li>
                          <li>
                            <a href="#">Plambing</a>
                          </li>
                          <li>
                            <a href="#">Features</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

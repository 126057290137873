import React from "react";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "./Slider";
import About from "./About";
import Counter from "./Counter";
import Team from "./Team";
import { Helmet } from "react-helmet";
import Reviews from "./Reviews";
// import Pop from "../Pop";

const Index = () => {
  const navigate = useNavigate ();
  useEffect (
    () => {
      navigate ("/#");
    }, []
  );

  return (
    <div className="wrapper">
      <Helmet>
        <script src="../../assests/js/vendor/jquery-1.12.0.min.js"></script>
        <script src="../../assests/js/bootstrap.min.js"></script>
        <script src="../../assests/js/plugins.js"></script>
        <script src="../../assests/js/slick.min.js"></script>
        <script src="../../assests/js/owl.carousel.min.js"></script>
        <script src="../../assests/js/waypoints.min.js"></script>
        <script src="../../assests/js/main.js"></script>

        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.carousel.min.css"
        ></link>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/owl-carousel/1.3.3/owl.theme.min.css"
        ></link>
      </Helmet>
      <Slider />
      <About />
      <Counter />
      <Reviews />
      {/* <Team /> */}
      {/* <Testimonial /> */}
    </div>
  );
};

export default Index;

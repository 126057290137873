import React from "react";

export const Contact = () => {

  return (
    <div>
      <div id="contact" className="htc__contact__address add-res bg__cat--1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="htc__contact__wrap clearfix">
                <div className="ht__address__inner">
                <a href="https://goo.gl/maps/d6afhxK244oc8B73A" target="_blank">
                {/* <a href="https://g.page/kpcivilconstruction?share"target = "_blank"> */}
                  <div className="ht__address__icon map-pin-location">
                    <i className="zmdi zmdi-pin"></i>
                  </div>
                  </a>
                  <div className="address_details">
                    <p>No.1/188,KP Towers, Ottiambakkam Main Rd,</p>
                    <p>Sithalapakkam, Chennai, Tamil Nadu 600126</p>
                  </div>
                </div>
                <div className="ht__address__inner">
                <a href="mailto:info@kpcivilconstruction.com" target={"_blank"}>
                  <div className="ht__address__icon glob">
                    <i className="zmdi zmdi-email" aria-hidden="true"></i>
                  </div>
                  </a>
                  <div className="mail__details_contact">
                    <p>
                      <a href="mailto:info@kpcivilconstruction.com" target="_blank">info@kpcivilconstruction.com </a>
                    </p>
                  </div>
                </div>
                <div className="ht__address__inner">
                  <div className="address__icon">
                    <i className="zmdi zmdi-phone" aria-hidden="true"></i>
                    </div>
                  <div className="mobile__details">
                    <p>
                      <a href="tel:+91 9840788800" target="_blank">+91 9840788800</a>
                    </p>
                    <p>
                      <a href="tel:+91 93810 21203" target="_blank">+91 93810 21203</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { toast } from "react-toastify";

const toastConfig = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

const notif = {
  error: ({ msg, timeout = 3000, customConfig = null }) => {
    setTimeout(() => {
      toast.error(
        msg,
        customConfig || {
          ...toastConfig,
          autoClose: timeout,
        }
      );
    }, 0);
  },
  success: ({ msg, timeout = 3000, customConfig = null }) => {
    setTimeout(() => {
      toast.success(
        msg,
        customConfig || {
          ...toastConfig,
          autoClose: timeout,
        }
      );
    }, 0);
  },
};

export default notif;

import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { send } from "emailjs-com";
import client1 from "../../assets/images/test/client/1.jpg";
import { Carousel } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import emailjs from "emailjs-com";

import { notif, sendEnquiry } from "../../appFunctions";

export const Testimonial = () => {
  const formRef = useRef();
  useEffect(() => {
    Aos.init();
  }, []);

  (function () {
    emailjs.init("DVDn6E0RqzDypKK9o");
  })();

  const [isSending, setIsSending] = useState(false);
  const sendButtonTxt = isSending ? "Sending..." : "Send Message";

  const emptyForm = {
    name: "",
    email: "",
    telephone: "",
    subject: "",
    message: "",
  };
  const [toSend, setToSend] = useState(emptyForm);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();

      setIsSending(true);

      if (toSend.name === "" || toSend.email === "" || toSend.message === "") {
        notif.error({
          msg: `Cannot Send an empty response
Please fill all the fields.`,
          timeout: 15000,
        });
        setIsSending(false);
        return;
      }

      await sendEnquiry({
        sendData: toSend,
        afterSend: () => {
          setIsSending(false);
        },
      });

      // send("service_uclcv7n", "template_oftqbcj", toSend, "DVDn6E0RqzDypKK9o")
      //   .then((response) => {
      //     console.log("SUCCESS!", response.status, response.text);
      //   })
      //   .catch((err) => {
      //     console.log("FAILED...", err);
      //   });

      // e.target.reset();

      formRef.current.reset();
      const allInputsINForm = formRef.current.querySelectorAll("input");

      setToSend(emptyForm);

      allInputsINForm.forEach((input) => {
        input.value = "";
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value }, e.target.reset);
  };

  return (
    <div
      data-aos="fade-zoom-in"
      data-aos-offset="200"
      data-aos-easing="ease-in-sine"
      data-aos-duration="600"
    >
      <section
        id="testimonials"
        className="htc__testimonial__area ptb--130"
        data--gray__overlay="9.5"
        // style="background: rgba(0, 0, 0, 0) url(images/bg/1.jpg) no-repeat fixed center center / cover ;"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-5 col-sm-12 col-xs-12">
              <div className="htc__testimonial__wrap">
                <div className="section__title text-left">
                  <div className="ht__address__inner">
                    <a
                      href="https://goo.gl/maps/d6afhxK244oc8B73A"
                      target="_blank"
                    >
                      {/* <a href="https://g.page/kpcivilconstruction?share"target = "_blank"> */}
                      <div className="ht__address__icon map-pin">
                        <i className="zmdi zmdi-pin"></i>
                      </div>
                    </a>
                    <div className="ht__address__details">
                      <p>No.1/188,KP Towers, Ottiambakkam Main Rd,</p>
                      <p>Sithalapakkam, Chennai, Tamil Nadu 600126</p>
                    </div>
                  </div>
                  <br />
                  <div className="ht__address__inner">
                    <a
                      href="mailto:info@kpcivilconstruction.com"
                      target="_blank"
                      color="black"
                    >
                      <div className="ht__address__icon_glob">
                        <i className="zmdi zmdi-email" aria-hidden="true"></i>
                      </div>
                    </a>
                    <div className="ht__address__details_mail">
                      <p>
                        <a
                          href="mailto:info@kpcivilconstruction.com"
                          target="_blank"
                          color="black"
                        >
                          info@kpcivilconstruction.com{" "}
                        </a>
                      </p>
                    </div>
                  </div>
                  <br />
                  <div className="ht__address__inner">
                    <div className="ht__address__icon">
                      <i className="zmdi zmdi-phone" aria-hidden="true"></i>
                    </div>
                    <div className="ht__address__details">
                      <p>
                        <a href="tel:+91 9840788800" target="_blank">
                          +91 9840788800
                        </a>
                      </p>
                      <p>
                        <a href="tel:+91 93810 21203" target="_blank">
                          +91 93810 21203
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                {/* <Carousel>
                  <Carousel.Item>
                    <div className="testimonial">
                      <div className="testimonial__thumb">
                        <img src={client1} alt="clint image" />
                      </div>
                      <div className="testimonial__details">
                        <div className="tes__icon">
                          <i className="fa fa-quote-right"></i>
                        </div>
                        <p>
                          On the other hand, we denounce with righteous
                          indignation and the tdislike men who are beguiled{" "}
                        </p>
                        <h2>
                          <a href="#">Ken Williams</a>
                        </h2>
                        <h4>CEO, Alves</h4>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="testimonial">
                      <div className="testimonial__thumb">
                        <img src={client1} alt="clint image" />
                      </div>
                      <div className="testimonial__details">
                        <div className="tes__icon">
                          <i className="fa fa-quote-right"></i>
                        </div>
                        <p>
                          On the other hand, we denounce with righteous
                          indignation and the tdislike men who are beguiled{" "}
                        </p>
                        <h2>
                          <a href="#">Ken Williams</a>
                        </h2>
                        <h4>CEO, Alves</h4>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="testimonial">
                      <div className="testimonial__thumb">
                        <img src={client1} alt="clint image" />
                      </div>
                      <div className="testimonial__details">
                        <div className="tes__icon">
                          <i className="fa fa-quote-right"></i>
                        </div>
                        <p>
                          On the other hand, we denounce with righteous
                          indignation and the tdislike men who are beguiled{" "}
                        </p>
                        <h2>
                          <a href="#">Ken Williams</a>
                        </h2>
                        <h4>CEO, Alves</h4>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-6  col-lg-offset-1 col-sm-12 col-xs-12 smt-40">
              <div className="reguest__quote">
                <div className="section__title text-left">
                  <h2 className="title__line">
                    REQUEST A <span className="text--theme">QUOTE</span>
                  </h2>
                  <p>
                    "Don't be a stranger. Kindly fill the below details and we
                    will get back to you at the earliest."
                  </p>
                </div>
                <form
                  ref={formRef}
                  id="contact-form"
                  action="https://d29u17ylf1ylz9.cloudfront.net/simply-construction/mail-faq.php"
                  method="post"
                  onSubmit={onSubmit}
                >
                  <div className="clint__comment__form">
                    <div className="single__cl__form">
                      <input
                        name="name"
                        type="text"
                        maxLength={50}
                        placeholder="Name"
                        required
                        value={toSend.name}
                        onChange={handleChange}
                      />
                      <input
                        name="email"
                        type="email"
                        placeholder="Email"
                        required
                        value={toSend.email}
                        maxLength="50"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="single__cl__form">
                      <input
                        name="telephone"
                        type="number"
                        maxLength={10}
                        minLength={10}
                        placeholder="Phone"
                        value={toSend.telephone}
                        onChange={handleChange}
                        required
                      />
                      <input
                        name="subject"
                        type="text"
                        maxLength={50}
                        placeholder="Subject"
                        value={toSend.subject}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="single__cl__message">
                      <textarea
                        name="message"
                        placeholder="Message"
                        value={toSend.message}
                        maxLength="500"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="clint__submit__btn">
                      <button
                        className="submit htc__btn"
                        type="submit"
                        onClick={onSubmit}
                      >
                        {sendButtonTxt}
                      </button>
                    </div>
                  </div>
                </form>
                <p className="form-messege"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
